.App {
  text-align: center;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
  height: 100vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.MuiTypography-root,
.App-header {
  justify-content: center;
}

.MuiButtonBase-root.css-sghohy-MuiButtonBase-root-MuiButton-root {
  display: block;
  margin: 8px auto 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: calc(10px + 2vmin);
  color: white;
}

.modal-panel {
  height: 85vh;
  overflow: scroll;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiTableRow-head
  .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary {
  display: none;
}

.user-icons,
.MuiTypography-root {
  display: flex;
}

.user-icons .user-icon {
  padding: 0 11px;
}

.MuiModal-root .MuiBox-root {
  width: 70vw;
}

.user-details-title {
  font-weight: bold;
  margin-right: 10px;
}

.pagination-container {
  position: relative;
  bottom: 2px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: auto;
}

.pagination-container .page-numer-list {
  margin-top: 22px;
}

.MuiFormControl-root {
  margin-left: 35px;
}

.MuiFormControl-root.MuiTextField-root.filter-field {
  margin-top: 18px;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.filter-icon {
  margin-top: 24px;
}

.candidates-container {
  display: flex;
  position: relative;
}

.upload-file-container .MuiFormControl-root.MuiTextField-root {
  width: 270px;
}

.pagination-container .upload-file-container {
  display: flex;
  flex-direction: column;
}

.pagination-container .MuiFormControl-root {
  margin-right: 25px;
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters {
  text-transform: uppercase;
}

.filter-container .MuiInputBase-root {
  min-width: 155px;
  margin: 26px 0 0 -30px;
}

.filter-container .MuiSelect-select {
  padding: 10px;
  padding-left: 35px;
}

.err,
.MuiTableRow-root .deleted {
  color: red;
}

.form-control {
  text-align: center;
}

.MuiTableRow-root .approved {
  color: green;
}

.MuiTableRow-root .to_verify {
  color: blue;
}

.MuiTableRow-root {
  height: 53px !important;
}

.filter-container {
  position: relative;
}

.btn-filters {
  color: black !important;
  position: absolute !important;
  top: 5px;
  left: 130px;
}

.form-control.to-edition,
.marked.list-group-item,
.candidate-seniority-dropdown.to-edition .dropdown-toggle {
  border: 1px solid red;
}

.edit-candidate-title,
.edit-form-button-group {
  text-align: center;
}

.error-message {
  color: red;
  text-align: left;
  padding: 15px;
}

.column-name {
  display: flex;
  flex-direction: column;
  min-width: 230px;
  align-items: center;
}

.column-input-container {
  display: flex;
  align-items: center;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.candidates-container {
  min-height: 84vh;
  display: flex;
  flex-flow: column;
  padding: 0;
}

.MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input {
  text-align: center;
}

.modal-panel.MuiBox-root {
  overflow-x: hidden;
}

.column-name .MuiButtonBase-root.MuiTableSortLabel-root {
  margin-left: 18px;
}

@media (min-width: 1600px) {
  .App {
    // overflow-y: hidden;
  }
}
